@import "../../globalStyles/variables";
@import "../../globalStyles/buttonsMixins";

.edit_form__content {
	text-align: center;

	padding: 0 2rem;
}

.edit_form__title {
	font-size: 2rem;
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.edit_form__text {
	font-size: 1.6rem;

	p:not(:last-child) {
		margin-bottom: .8rem;
	}


	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.edit_form__button {
	@include btn_size_base;
	@include btn_style_secondary;
	width: 100%;
}