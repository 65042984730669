@import "../../globalStyles/variables";

.catalog_item__link {
	position: relative;

	display: flex;
	align-items: center;
	padding: 1.2rem var(--gl-indent);

	transition: backgeound .3s ease;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			background-color: rgba(244, 64, 54, .2);
		}
	}
}

.catalog_item__text {
	position: relative;
	z-index: 2;

	display: block;
	width: calc(100% - 2rem);
	font-size: 1.4rem;
}

.catalog_item__arrow {
	font-size: 2rem;

	transform: rotate(-90deg);

	&:not(:first-child) {
		margin-left: 1.2rem;
	}
}