@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/buttonsMixins";
@import "../../globalStyles/formMixins";

.cart {
	position: relative;
}

.cart__bottom {
	position: relative;
	z-index: 10;
	background-color: $bg_dedicated_color;
	border-radius: .4rem;
	overflow: hidden;
	box-shadow: rgba($black, .2) 0 .2rem 1rem 0, rgba($black, .1) 0 .2rem .4rem 0, rgba($white, .05) 0 0 0 .1rem inset;
}

.cart__bottom_in {
	display: flex;
	justify-content: space-between;

	height: 6.4rem;
	gap: 2rem;
	padding: 1.2rem 2rem;

	background-color: $bg_dedicated_color;
	border-radius: .4rem;
}

.cart__button {
	@include btn_size_base;
	min-width: 18rem;

	&.cart__button--secondary_mod {
		@include btn_style_secondary;
		width: calc(65% - 1rem);
	}

	&.cart__button--primary_mod {
		@include btn_style_primary;
		width: calc(35% - 1rem);
	}
}

.cart__body {
	border-radius: .4rem;
	overflow: hidden;
	box-shadow: rgba($black, .2) 0 .2rem 1rem 0, rgba($black, .1) 0 .2rem .4rem 0, rgba($white, .05) 0 0 0 .1rem inset;
	background-color: $bg_dedicated_color;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.cart__head {
	position: relative;
	display: flex;
	align-items: center;
	padding: 1.2rem 2rem;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 2px;

		background-color: $text_color;
		opacity: 0.4;
	}

	@include bp($point_3) {
		display: none;
	}
}

.cart__col {
	font-size: 1.6rem;
	font-weight: 700;
	text-align: center;

	&:nth-child(1) {
		width: calc(100% - 38rem);
	}

	&:nth-child(2) {
		width: 18rem;
	}

	&:nth-child(3) {
		width: 20rem;
	}

	&:nth-child(4) {
		width: 14rem;
	}
}

.cart__message {
	padding: 2rem;
	font-size: 1.6rem;
	text-align: center;

	p:not(:last-child) {
		margin-bottom: .8rem;
	}
}

.cart_total {
	padding: 2rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: flex-end;

}

.cart_total__item {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.cart_total__label {
	font-size: 2rem;
	font-weight: 700;
}

.cart_promo {
	display: flex;
	align-items: flex-end;
	gap: 2rem;
	padding: 2rem;
}

.cart_promo__input {
	flex: 1 1 auto;

	input {
		@include default_input;
	}
}

.cart_promo__button {
	@include btn_size_base;
	@include btn_style_secondary;
}

.cart__checkbox {
	padding: 2rem 2rem 0;
}