@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.filter_item {
	padding: 2rem;
}

.filter_item__button {
	display: flex;
	align-items: center;
	width: 100%;

	font-size: 2rem;
	color: $text_color;
}

.filter_item__title {
	font-weight: 700;
	text-align: left;
}

.filter_item__icon {
	margin-left: auto;

	transition: transform .3s ease;

	.filter_item--active_state & {
		transform: rotateX(-180deg);
	}
}

.filter_item__content {
	margin: 0 -2rem;
	transition: all .3s ease-in-out 0s;

	&:not(.filter_item--select_open_state &) {
		overflow: hidden;
	}

	&:not(.filter_item--active_state &) {
		opacity: 0;
		pointer-events: none;
	}

	.filter_item--active_state & {
		opacity: 1;
		pointer-events: auto;
	}
}

.filter_item__wrap {
	@include bp($point_2, min) {
		padding: 3rem 2rem 0;
	}

	@include bp($point_3) {
		padding: 3rem 9rem 0 2rem;
	}
}

.filter_item__checkbox {
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}