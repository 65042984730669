@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/mixins";

.single_news {
	padding: 3rem var(--gl-indent);
}

.single_news__head {
	display: flex;
	align-items: flex-end;
	gap: 2rem;

	&:not(:last-child) {
		margin-bottom: 2.6rem;
	}
}

.single_news__title {
	@include h3;
	flex: 1 1 auto;
}

.single_news__date {
	flex-shrink: 0;
	font-size: 1.6rem;
	color: $text_color;
	opacity: 0.8;
}

.single_news__image {
	position: relative;
	display: block;
	width: 100%;
	padding-bottom: 28%;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}

	picture {
		position: absolute;
		top: 0;
		left: 0;

		img {
			object-position: center;
		}
	}
}