@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.nav_bar {}

.nav_bar__list {
	@include bp($point_2, min) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		margin: 0 -2rem -2rem 0;
	}
}

.nav_bar__item {
	@include bp($point_2, min) {
		padding: 0 2rem 2rem 0;
	}

	@include bp($point_3) {
		display: flex;
		justify-content: center;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.nav_bar__wrap {
	position: relative;

	display: flex;
	align-items: center;

	padding: 1rem;

	@media (any-hover: hover) {
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 6;

			width: 100%;
			height: .2rem;

			background-color: $primary_color;
			pointer-events: none;
			opacity: 0;
			transition: opacity .3s ease;
			content: '';
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}
	}
}

.nav_bar__link {
	position: relative;
	z-index: 6;

	font-size: 1.6rem;
	text-transform: uppercase;

	transition: color .3s ease;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;

			&:not(.nav_bar__wrap &) {
				color: $primary_color;
			}
		}
	}
}

.nav_bar__icon {
	position: relative;
	z-index: 6;

	font-size: 1.8rem;
	color: inherit;

	transition: transform .3s ease;
	will-change: transform;

	&:not(:first-child) {
		margin-left: .4rem;
	}

	@media (any-hover: hover) {
		.nav_bar__wrap:hover & {
			transform: rotateЧ(-180deg);
		}
	}
}