@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.single_product_slider {}

.single_product_slider__main {
	position: relative;

	padding: 2rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}

	.swiper {
		overflow: visible;
	}

	.swiper-slide {
		height: auto;
	}
}

.single_product_slider__arrow {
	position: absolute;
	top: 50%;
	z-index: 3;

	background: transparent;

	@media (any-hover: hover) {
		&:hover {
			background: transparent;
		}
	}

	&.swiper-button-disabled {
		opacity: 1;
	}

	&.single_product_slider__arrow--prev_mod {
		left: 0;
		transform: translateY(-50%) rotate(-180deg);
	}

	&.single_product_slider__arrow--next_mod {
		transform: translateY(-50%);
		right: 0;
	}
}

.single_product_slider__container {
	max-width: 42rem;
	margin-left: auto;
	margin-right: auto;
}

.single_product_slider__thumbs {
	.swiper-slide {
		// width: max(13%, 7rem);
	}
}

.single_product_slider__wrap {
	position: relative;

	display: block;
	width: 100%;
	padding-bottom: 100%;

	background-color: $white;
	cursor: pointer;

	&.single_product_slider__wrap--main_mod {}

	&:not(.single_product_slider__wrap--main_mod) {
		border-radius: .8rem;
		border: .3rem solid $white;

		overflow: hidden;
		transition: border 0.3s ease 0s;
	}

	.swiper-slide-thumb-active & {
		border: .3rem solid $primary_color;
	}
}

.single_product_slider__image {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	height: 100%;
}

.single_product_slider__video {
	position: relative;

	display: block;
	width: 100%;
	height: 100%;
	margin-top: -2rem;
	margin-left: -2.1rem;

	cursor: pointer;
}

.single_product_slider__iframe {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;

	width: calc(100% + 4.2rem);
	height: calc(100% + 4rem);
}


.single_product_slider__icon {
	position: absolute;
	z-index: 2;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	color: $primary_color;

	@include bp($point_2, min) {
		font-size: 6rem;
	}

	@include bp($point_3) {
		font-size: 4rem;
	}
}