@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/buttonsMixins";

.reviews {
	position: relative;
}

.reviews__block {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.reviews__loader {
	position: absolute;
	z-index: 5;
	top: -3rem;
	left: 50%;
	transform: translateX(-50%);

	width: 100vw;
	height: calc(100% + 3rem);
}

.reviews__head {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}

	&.reviews__head--no_reviews_mod {
		text-align: center;
		justify-content: center;
	}
}

.reviews__button {
	@include btn_style_primary;
	@include btn_size_normal;
}

.reviews__wrap {
	&:not(:last-child) {
		margin-right: 2rem;
	}
}

.reviews__title {
	@include h2;

	.reviews__head--no_reviews_mod & {
		&:not(:last-child) {
			margin-bottom: 3rem;
		}
	}

	&:not(:last-child) {
		margin-bottom: .6rem;
	}
}

.reviews__average_rating {
	display: flex;
	align-items: center;

	span {
		color: $text_color;
		font-size: 1.4rem;
		line-height: 0;
		font-weight: 700;

		&:not(:first-child) {
			margin-left: .6rem;
		}
	}
}

.reviews__item {
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.reviews__text {
	font-size: 1.6rem;

	p:not(:last-child) {
		margin-bottom: .4rem;
	}

	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}