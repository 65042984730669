@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/buttonsMixins";

.product_filtres {
	position: relative;
	z-index: 5;
	padding-top: 3rem;

	@include bp($point_2, min) {
		box-shadow: rgba($black, 0.2) 0 .2rem 1rem 0, rgba($black, 0.1) 0 .2rem .4rem 0, rgba($white, 0.05) 0 0 0 .1rem inset;
		background-color: $bg_dedicated_color;
		border-radius: 1rem;
	}
}

.product_filtres__body {
	position: relative;
	z-index: 3;
}

.product_filtres__item {
	&:not(:last-child) {
		position: relative;

		&::after {
			position: absolute;
			bottom: 0;
			left: 0;

			width: 100%;
			height: .1rem;

			background-color: $text_color;
			opacity: .6;
			content: '';
		}
	}
}

.product_filtres__button {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;

	font-size: 2rem;
}

.product_filtres__wrapper {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 4;

	transition: opacity 0.3s ease 0s;
	will-change: transform;


	&.product_filtres__wrapper--active_state {
		pointer-events: initial;
		opacity: 1;
	}

	&:not(.product_filtres__wrapper--active_state) {
		pointer-events: none;
		opacity: 0;
	}

	@include bp($point_2, min) {
		width: 12rem;
		margin-right: -12rem;
	}

	@include bp($point_3) {
		width: 8rem;
		margin-right: .6rem;
	}
}

.product_filtres__reset {
	position: absolute;
	top: .4rem;
	right: .4rem;
	z-index: 5;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.2rem;
	padding: 0 .6rem;

	font-size: 1.8rem;
	font-family: $font_heading;
	border-bottom: .2rem solid $text_color;
	color: $text_color;
	transition: color 0.3s ease, border-color .3s ease;


	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
			border-color: $primary_color;
		}
	}

	span {
		display: block;
		font-size: 1.2rem;

		&:not(:last-child) {
			margin-right: .4rem;
		}
	}
}

.product_filtres__sumbit {
	position: relative;
	top: 1rem;

	height: 3rem;
	width: 100%;
	border-radius: .2rem .8rem .8rem .2rem;

	font-weight: 700;
	color: $white;

	@include bp($point_2, min) {
		background: $bg_dedicated_color;
		font-size: 1.4rem;
		padding: 0 1.6rem 0 1.2rem;
	}

	@include bp($point_3) {
		background-color: $bg_menu_color;
		font-size: 10px;
		padding: 0 max(.6rem, 4px);
	}

	span {
		position: relative;
		z-index: 3;

		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;

		transition: background 0.3s ease 0s;
	}

	&::before {
		top: 10%;
		left: 5%;
		z-index: 2;

		width: 90%;
		height: 80%;
		border-radius: inherit;

		background: $primary_color;
	}

	&::after {
		top: 10%;
		left: .7rem;
		z-index: 1;
		transform: translateX(-100%);

		width: 1.2rem;
		height: 80%;
		clip-path: polygon(0 50%, 100% 100%, 100% 0);

		background: $primary_color;
	}

	@media (any-hover: hover) {
		&:hover {

			&::before,
			&::after {
				background: darken($primary_color, 20%);
			}
		}
	}

	@include st_btn;
}