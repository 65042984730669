@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.available_table {
	display: block;
	width: 100%;

	border-radius: .2rem;
	overflow: hidden;
	background-color: $bg_dedicated_color;
	border-left: 1px solid $grey;
	border-top: 1px solid $grey;

	tr,
	thead {
		display: flex;
		width: 100%;
	}

	td {
		display: block;
		width: 50%;
		padding: 1rem 2rem;

		font-size: 1.6rem;

		border-right: 1px solid $grey;
		border-bottom: 1px solid $grey;
	}
}

.available_table__head {
	position: relative;

	font-weight: 700;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-color: $black;
		opacity: 0.2;
	}
}

.available_table__body {
	display: block;
	width: 100%;
}