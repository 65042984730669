@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";

.timer-container {
  width: 100%;
  max-width: 343px;
  height: 109px;
  padding: 14px 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 22px 0 0;
}

.timer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 295px;
  padding-right: 5px;
}

.time-unit {
  text-align: center;
  flex: 1; 
}

.time-value {
  color: black;
  font-weight: 700;
  font-size: 28px;
  font-family: $font_heading;
  line-height: 38.84px;
}
 
.time-label {
  color: gray;
  font-size: 12px;
  font-weight: 500;
  font-family: $font_text;
  width: 51px;
  line-height: 14.63px;
}

.separator {
  height: 16px;
  border-left: 2px solid black;
}

.red {
  color: red;
}

.promotion-period {
  text-align: center;
  margin-top: 12px;
  font-weight: 500;
  font-family: $font_text;
  color: gray;
  font-size: 12px;
  line-height: 14.63px;
}

@media (max-width: 440px) {
  .time-value {
    font-size: 20px;
  }

  .time-label {
    font-size: 10px;
    width: auto;
  }

  .separator {
    height: 12px;
    border-left: 1px solid black;
  }

  .timer {
    gap: 10px;
  }

  .promotion-period {
    font-size: 10px;
  }
}
