@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.single_product_tabs__nav {
	overflow-x: auto;
	overflow-y: hidden;

	margin-left: calc(var(--gl-indent) * -1);
	width: 100vw;
	background-color: $bg_dedicated_color;

	@include redesign_scrollbar;
}

.single_product_tabs__list {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single_product_tabs__button {
	position: relative;

	flex: 1 1 auto;
	text-align: center;

	font-family: $font_heading;
	font-weight: 700;
	cursor: pointer;

	span {
		position: relative;
		z-index: 2;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-color: $primary_color;
		transform: scaleX(0);
		transition: transform 0.5s ease 0s, opacity .5s ease 0s;
		opacity: 0.6;
	}

	@media (any-hover: hover) {
		&:hover {
			&::before {
				transform: scaleX(1);
				opacity: 1;
			}
		}
	}

	&.react-tabs__tab--selected {
		pointer-events: none;

		&::before {
			transform: scaleX(1);
		}
	}

	@include bp($point_4, min) {
		font-size: 1.8rem;
		padding: 2rem 4rem;

	}

	@include bp($point_4 - 1) {
		font-size: 1.6em;
		padding: 1.4rem .8rem;

	}
}