@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";

.single_promotion {
	padding: 3rem var(--gl-indent) 0;
}

.single_promotion__wrap {
	&:not(:last-child) {
		margin-bottom: 6rem;
	}
}

.single_promotion__row {
	background-color: $bg_dedicated_color; 
	border-radius: 8px !important;
	overflow: hidden;

	@include bp($point_2, min) {
		display: flex;
		gap: 30px;
		// margin-right: -6rem;
	}
}

.single_promotion__column1 {
	// border: 3px solid $bg_dedicated_color;

	@include bp($point_3) {
		&:not(:last-child) {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
	}

	@include bp($point_6) {
		&:not(:last-child) {
			margin-top: 0;
		}
	}
}

.single_promotion__cover {
	max-width: 420px;
	height: 320px;
	object-fit: cover;
	border-radius: 8px !important;
	overflow: hidden;

	@include bp($point_3) {
		margin: 0 auto;
	}
	
}

.single_promotion__column2 {
	@include bp($point_2, min) {
		padding-right: 6rem;
		flex: 1
	}

	@include bp($point_3) {
		&:not(:last-child) {
			margin-bottom: 4rem;
		}
	}
}

.promotion_description {
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: $bg_dedicated_color;
	padding: 20px;

	@include bp($point_3) {
		padding: 10px;
	}
}

.promotion_description__title {
	@include h3;

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}
}

.promotion_description__description {
	color: $text_color;
	font-weight: 500;
	font-size: 1.6rem;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 19.5px;
	margin: 10px 0;
}