@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.footer {
	position: relative;

	width: 100%;
	font-size: 1rem;

	padding: 4rem var(--gl-indent) 2rem;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: .1rem;

		background-color: $text_color;
		opacity: .6;
	}
}

.footer_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;
}

.footer__body {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.footer__row {
	display: flex;
	flex-wrap: wrap;

	@include bp($point_2, min) {
		margin: 0 -2rem -2rem 0;
	}

	@include bp($point_3) {
		margin: 0 -2rem -4rem 0;

		justify-content: center;
		text-align: center;
	}
}

.footer__column {
	@include bp($point_2, min) {
		padding: 0 2rem 2rem 0;

		&:nth-child(3n+1) {
			width: 25%;
		}

		&:nth-child(3n+2) {
			width: 50%;
		}

		&:nth-child(3n+3) {
			width: 25%;
		}
	}

	@include bp($point_3) {
		padding: 0 2rem 4rem 0;
	}

	@include bp($point_3, $point_4) {
		&:not(:last-child) {
			width: 33.333%;
		}

		&:last-child {
			width: 100%;
		}
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.footer__title {
	text-transform: uppercase;
	font-weight: 300;
	text-align: center;

	&:not(:last-child) {
		margin-bottom: 2.8rem;
	}

	@include bp($point_2, min) {
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		font-size: 2rem;

	}
}

.footer__social {
	display: flex;
	flex-direction: column;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.footer__buttons {
	display: flex;
	justify-content: center;
}

.footer__bottom {
	display: flex;
	align-items: center;

	@include bp($point_4 - 1) {
		flex-direction: column;
		text-align: center;
	}
}

.footer__payments {
	flex: 1 1 auto;

	@include bp($point_4, min) {

		&:not(:last-child) {
			margin-right: 2rem;
		}
	}

	@include bp($point_4 - 1) {
		&:not(:last-child) {
			margin-bottom: 4rem;
		}
	}
}

.footer__copy {
	font-size: 1.4rem;
	color: $text_color;
	line-height: 1.5;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	p:not(:last-child) {
		margin-bottom: .6rem;
	}

	a {
		display: flex;
		align-items: center;

		margin-left: auto;
		gap: .8rem;

		transition: color .3s ease;
		text-decoration: underline;

		&:hover,
		&:focus {
			color: $primary_color;
			text-decoration: none;
		}
	}
}

.footer__icon {
	font-size: 2rem;
}