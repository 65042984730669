@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	width: 100%;
	font-size: 1rem;

	transition: box-shadow 0.3s ease, transform 0.3s ease, filter 0.3s ease 0s;
	will-change: box-shadow, transform, filter;

	.body--blurred_state & {
		@include blur(1rem);
	}

	&.header--scroll_state {
		box-shadow: rgba($black, .4) 0 .2rem 2.4rem;
	}

	&.header--hidden_mod {
		transform: translateY(-100%);
		pointer-events: none;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: var(--height-header);

		background-color: $bg_color;
		pointer-events: none;

		transition: background-color .3s ease;

		@include bp($point_2, min) {
			z-index: 1;
		}

		@include bp($point_3) {
			z-index: 6;
		}
	}

	&:not(.body--open_menu_state &) {
		height: var(--height-header);
	}

	@include bp($point_2, min) {
		padding: 2rem var(--gl-indent);
	}

	@include bp($point_3) {
		padding: 1.2rem var(--gl-indent);
	}
}

.header_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;
}

.header__wrapper {
	.html--lock_padding_state & {
		padding-right: var(--scrollbar-width);
	}
}

.header__row {
	display: flex;
	flex-wrap: wrap;

	@include bp($point_2, min) {
		margin: 0 -2rem -2rem 0;
	}
}

.header__column {
	@include bp($point_2, min) {
		padding: 0 2rem 2rem 0;

		&:nth-child(3n+1) {
			display: flex;
			width: 24%;
		}

		&:nth-child(3n+2) {
			width: 55%;
		}

		&:nth-child(3n+3) {
			width: 21%;
			display: flex;
			justify-content: flex-end;
		}

		&:nth-child(3n+1),
		&:nth-child(3n+3) {
			min-height: 5.7rem;
			align-items: center;
		}

		&:not(:nth-child(2), :nth-child(3), :nth-child(4)) {
			position: relative;
			z-index: 3;
		}

		&:nth-child(2),
		&:nth-child(3) {
			position: relative;
			z-index: 4;
		}
	}

	@include bp($point_3) {
		&.header__column--desktop_mod {
			display: none;
		}

		&:not(.header__column--desktop_mod) {
			position: relative;
			z-index: 6;

			display: flex;
		}

		&.header__column--actions_mod {
			order: 1;
			flex-shrink: 0;

			align-items: center;
		}

		&.header__column--logo_mod {
			order: 2;
			flex: 1 1 auto;
			justify-content: center;
		}

		&.header__column--menu_mod {
			order: 3;
			flex-shrink: 0;
		}
	}
}

.header__logo {
	display: inline-block;
}

.header__container {
	display: flex;
	align-items: center;

	margin-right: -2rem;

	&>* {
		padding-right: 2rem;
	}
}

.header__lang {
	margin-left: auto;
}

.header__social {
	font-size: 2rem;

	@include bp($point_3) {
		display: none;
	}
}

.header__burger {
	@include bp($point_2, min) {
		display: none;
	}
}

.header__list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@include bp($point_2, min) {
		margin: 0 -3em -2em 0;
	}

	@include bp($point_3) {
		margin: 0 -2em -2em 0;
	}
}

.header__item {
	@include bp($point_2, min) {
		padding: 0 3em 2em 0;
	}

	@include bp($point_3) {
		padding: 0 2em 2em 0;

		&.header__item--desktop_mod {
			display: none;
		}
	}
}

.header__like {
	display: flex;
	width: 2.4rem;
	height: 2.4rem;

	&.header__like--active_state span {
		color: $primary_color;
	}

	@media (any-hover: hover) {
		&:hover {
			span {
				color: $primary_color;
			}
		}
	}

	span {
		font-size: 2.4rem;
		color: $text_color;

		transition: color 0.3s ease 0s;
		will-change: color;
	}
}

.header__menu_search {
	@include bp($point_2, min) {
		display: none;
	}

	@include bp($point_3) {
		position: absolute;
		z-index: 5;
		top: var(--height-header);
		left: 0;

		width: 100%;
		height: 6rem;
		padding: 1rem var(--gl-indent) 1rem;
		background-color: $bg_color;

		transition: opacity .3s ease, transform .3s ease;

		&:not(.body--open_menu_state &) {
			opacity: 0;
			transform: translateY(-100%);
			pointer-events: none;
		}

		.body--open_menu_state & {
			transform: translateY(0);
			opacity: 1;
			pointer-events: all;

		}
	}
}