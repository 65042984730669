@import "../../globalStyles/variables";

.checkbox {
	position: relative;

	display: block;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox__label {
	display: inline-flex;
	align-items: center;

	color: $text_color;
	cursor: pointer;
	transition: color .3s ease;

	a {
		transition: color .3s ease;
		text-decoration: underline;
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}

.checkbox__text {
	font-size: 1.4rem;

	small {
		font-size: 1rem;
	}
}

.checkbox__checkmark {
	position: relative;

	display: block;
	width: 2rem;
	height: 2rem;
	flex-shrink: 0;

	box-shadow: rgba($black, .2) 0 .1rem .4rem;
	border-radius: .4rem;
	transition: background .3s ease-out;

	.checkbox--primary_mod & {
		background: $bg_color;
	}

	.checkbox--secondary_mod & {
		background: $bg_dedicated_color;
	}

	&::after {
		position: absolute;
		top: 44%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);

		width: 60%;
		height: 30%;

		border: .2rem solid $text_color;
		border-top: none;
		border-right: none;

		opacity: 0;
		transition: opacity .3s ease-out;
		content: '';
	}

	&:not(:last-child) {
		margin-right: .6rem;
	}

}

.checkbox__input {
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
	margin: 0;
	z-index: -1;

	&:checked~.checkbox__checkmark {
		background-color: $primary_color;

		&:after {
			opacity: 1;
		}
	}
}