.favorites_section {
	position: relative;
	min-height: 20rem;
}

.favorites_section__body {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.favorites_section__message {
	padding: 2rem;
	font-size: 1.6rem;
	text-align: center;

	p:not(:last-child) {
		margin-bottom: .8rem;
	}
}