@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.profile_wrapper {
	padding: 3rem var(--gl-indent);

	.section_in {
		@include bp($point_2, min) {
			display: flex;
			align-items: flex-start;
		}
	}
}

.profile_wrapper__aside {
	border-radius: 1rem;
	box-shadow: rgba(0, 0, 0, 0.2) 0 0.2rem 1rem 0, rgba(0, 0, 0, 0.1) 0 0.2rem 0.4rem 0, rgba(255, 255, 255, 0.05) 0 0 0 0.1rem inset;

	background-color: $bg_dedicated_color;

	@include bp($point_2, min) {
		flex: 0 0 34rem;
		padding: 2rem;

	}

	@include bp($point_3) {
		padding: .8rem;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.profile_wrapper__content {
	@include bp($point_2, min) {
		padding: 0 2rem;
		width: calc(100% - 34rem);
	}
}

.profile_wrapper__list {
	@include bp($point_3) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 1.8rem;
		justify-content: space-around;
	}
}

.profile_wrapper__item {
	position: relative;

	@include bp($point_2, min) {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.profile_wrapper__link,
.profile_wrapper__button {
	position: relative;

	display: flex;
	align-items: center;
	width: 100%;

	font-weight: 700;

	background-color: $bg_color;
	color: $text_color;
	border-radius: .4rem;
	overflow: hidden;

	transition: background 0.3s ease, color .3s ease;

	@include bp($point_2, min) {
		padding: 1rem 2rem;
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		padding: .8rem 1.2rem;
		font-size: 1.4rem;
	}

	@media (any-hover: hover) {
		&:hover {
			background-color: rgba($primary_color, .4);
		}
	}

	span {
		display: block;
		flex-shrink: 0;


		margin-right: .6rem;

		@include bp($point_2, min) {
			font-size: 1.8rem;
		}

		@include bp($point_3) {
			font-size: 1.6rem;
		}
	}

	&.profile_wrapper__link--active_state {
		background-color: rgba($primary_color, .4);
		pointer-events: none;
	}
}

.profile_wrapper__link {
	color: $text_color;

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

.profile_wrapper__button {
	text-align: left;

	&.profile_wrapper__button--loading_state {
		pointer-events: none;
		opacity: 0.8;
	}
}

.profile_wrapper__message {
	padding-top: .6rem;

	font-size: 1.4rem;
	font-style: italic;

	@include bp($point_2, min) {
		opacity: 0.9;
	}

	@include bp($point_3) {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 2;

		width: 100%;
		background-color: var(--bg-dedicated);
		padding: 4px;
		border-radius: 4px;
	}
}