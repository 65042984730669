@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";

.single_product {
	padding: 3rem var(--gl-indent) 0;
}

.single_product__wrap {
	&:not(:last-child) {
		margin-bottom: 6rem;
	}
}

.single_product__row {
	@include bp($point_2, min) {
		display: flex;

		margin-right: -6rem;
	}
}

.single_product__column {
	@include bp($point_2, min) {
		padding-right: 6rem;
		width: 50%;
	}

	@include bp($point_3) {
		&:not(:last-child) {
			margin-bottom: 4rem;
		}
	}
}

.single_product__title {
	@include h2;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.single_product__tab {
	position: relative;
	padding-top: 3rem;
	min-height: 10rem;
}