@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.contacts_section {
	padding: 4rem var(--gl-indent);

	h3 {
		@include h3;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	p {
		display: flex;
		align-items: center;
		font-size: 1.8rem;

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}

		a {
			display: inline-flex;
			align-items: center;
			color: $primary_color;

			@media (any-hover: hover) {
				transition: opacity 0.3s ease 0s;

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}
		}

		.font_icon {
			font-size: 90%;
			flex-shrink: 0;

			display: inline-block;
			margin: 0 .4rem 0 1rem;
		}
	}

	strong {
		font-weight: 700;
		white-space: nowrap;
		min-width: 19rem;
	}
}