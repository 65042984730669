@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.footer_spoiler {
	@include bp($point_3) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.footer_spoiler__head {
	display: inline-flex;
	align-items: center;

	color: $text_color;

	@include bp($point_2, min) {
		&:not(:last-child) {
			margin-bottom: 2.6rem;
		}
	}

	@include bp($point_3) {
		cursor: pointer;
		transition: color .3s ease;

		.footer_spoiler--active_state & {
			color: $primary_color;
		}
	}
}

.footer_spoiler__icon {
	font-size: 1.6rem;
	color: inherit;

	&:not(:last-child) {
		margin-right: .6rem;
	}
}

.footer_spoiler__arrow {
	font-size: 1.6rem;
	color: inherit;
	transition: transform .3s ease;
	will-change: transform;

	&:not(:first-child) {
		margin-left: .6rem;
	}

	.footer_spoiler--active_state & {
		transform: rotateX(-180deg);
	}
}

.footer_spoiler__text {
	text-transform: uppercase;
	color: inherit;

	@include bp($point_2, min) {
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		font-size: 2rem;
	}
}

.footer_spoiler__content {
	@include bp($point_3) {
		transition: all .3s ease-in-out 0s;

		opacity: 0;
		pointer-events: none;
		overflow: hidden;
		max-height: 0px;

		.footer_spoiler--active_state & {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.footer_spoiler__list {
	@include bp($point_3) {
		padding-top: 2rem;
	}

	&.footer_spoiler__list--columns_mod {
		@include bp($point_2, min) {
			column-count: 2;
			break-inside: avoid;
		}
	}
}

.footer_spoiler__item {
	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}
}

.footer_spoiler__link {
	position: relative;
	display: inline-block;

	&::before {
		position: absolute;
		bottom: -.1rem;
		left: 0;

		width: 100%;
		height: .1rem;

		background-color: $primary_color;
		transform: scaleX(0);
		transition: transform .3s ease;
		will-change: transform;
		content: '';
	}

	@include bp($point_2, min) {
		font-size: 1.4rem;
	}

	@include bp($point_3) {
		font-size: 1.8rem;
	}

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;

			&::before {
				transform: scaleX(1);
			}
		}
	}
}