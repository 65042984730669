@import "../../../../globalStyles/variables";

.info_item {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.info_item__title {
	font-size: 2rem;
	font-weight: 700;

	.info_item--promo_mod & {
		color: $orange;
	}

	.info_item--sale_mod & {
		color: $blue;
		display: inline-block;
	}

	span {
		display: inline-block;

		.info_item--promo_mod & {
			margin-right: .8rem;
		}
	}

	&:not(:last-child) {
		.info_item--sale_mod & {
			margin-right: .8rem;
		}

		.info_item--promo_mod & {
			margin-bottom: .8rem;
		}
	}
}

.info_item__text {
	font-size: 1.6rem;

	.info_item--sale_mod & {
		display: inline-block;
	}
}