@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.products_sort {
	position: relative;
	z-index: 6;

	display: flex;
	align-items: center;

	height: 100%;


	@include bp($point_3) {
		position: relative;
		padding: 1.4rem 0;

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: calc(var(--gl-indent) * -1);

			width: 100vw;
			height: .1rem;

			pointer-events: none;
			background-color: $text_color;
			opacity: 0.4;
		}

		&::before {
			top: 0;
		}

		&::after {
			bottom: 0;
		}
	}
}

.products_sort__label {
	font-size: 1.8rem;

	&:not(:last-child) {
		margin-right: 1.2rem;
	}
}

.products_sort__select {
	min-width: 22rem;
}