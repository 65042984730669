@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;

	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
	min-height: 100vh;

	background-color: $bg_menu_color;

	transition: transform .3s ease, opacity .3s ease;
	will-change: transform, opacity;

	&:not(.body--open_menu_state &) {
		transform: translateY(-100%);
		opacity: 0;
		pointer-events: none;
	}

	.body--open_menu_state & {
		transform: translateY(0);
		opacity: 1;
		pointer-events: initial
	}

	@include redesign_scrollbar;

	@include bp($point_2, min) {
		display: none;
	}
}

.menu__body {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;

	padding: calc(var(--height-header) + 8rem) 4rem 6rem;

	overflow-x: hidden;

	&:not(.body--mega_menu_state &) {
		overflow-y: auto;
	}

	.body--mega_menu_state & {
		overflow-y: hidden;
	}

	@include redesign_scrollbar;
}

.menu__catalog {
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.menu__wrap {
	flex: 1 1 auto;
	column-count: 2;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.menu__social {
	display: flex;
	justify-content: center;

	font-size: 2.4rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.menu__theme_btn {
	position: absolute;
	top: calc(var(--height-header) + 7rem);
	right: 1rem;
	z-index: 100;

}