@import "../../globalStyles/variables";
@import "../../globalStyles/formMixins";
@import "../../globalStyles/buttonsMixins";
@import "../../globalStyles/typographyMixins";

.notifications__body {
	background-color: $bg_dedicated_color;
	border-radius: .4rem;
	overflow: hidden;
	box-shadow: rgba($black, .2) 0 .2rem 1rem 0, rgba($black, .1) 0 .2rem .4rem 0, rgba($white, .05) 0 0 0 .1rem inset;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.notifications__head {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1.2rem 2rem;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 2px;

		background-color: $text_color;
		opacity: 0.4;
	}

	@include bp($point_3) {
		display: none;
	}
}

.notifications__clear-button {
	@include btn_style_secondary;
	@include btn_size_base;

	height: 30px;
}

.notifications__item {
	&:not(:last-child) {
		border-bottom: 1px solid $grey;
	}
}

.notifications__message {
	padding: 2rem;
	font-size: 1.6rem;
	text-align: center;

	p:not(:last-child) {
		margin-bottom: .8rem;
	}
}