@import "../../globalStyles/variables";

.loader {
	top: 0;
	z-index: 200;

	display: flex;
	justify-content: center;

	background-color: rgba($black, .96);

	&.loader--local_mod {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		width: 100vw;
		height: 100%;
	}

	&.loader--btn_mod {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border-radius: inherit;
		overflow: hidden;
	}

	&.loader--fixed_mod {
		position: fixed;
		left: 0;

		width: 100vw;
		height: 100vh;

		align-items: center;
	}
}

.loader__in {
	display: inline-block;
	position: relative;

	.loader--btn_mod & {
		width: 3rem;
		height: 3rem;
		margin: auto;

		font-size: 4px;
	}

	&:not(.loader--btn_mod &) {
		width: 8rem;
		height: 8rem;
		font-size: 10px;
	}

	.loader--local_mod & {
		top: 6em;
	}

	div {
		animation: spinner 1.6s cubic-bezier(.5, 0, .5, 1) infinite;
		transform-origin: 4em 4em;

		&::after {
			content: " ";
			display: block;
			position: absolute;
			width: .7em;
			height: .7em;
			border-radius: 50%;
			background: $primary_color;
			margin: -.4em 0 0 -.4em;
		}

		&:nth-child(1) {
			animation-delay: -0.036s;

			&::after {
				top: 6.3em;
				left: 6.3em;
			}
		}

		&:nth-child(2) {
			animation-delay: -0.072s;

			&::after {
				top: 6.8em;
				left: 5.6em;
			}
		}

		&:nth-child(3) {
			animation-delay: -0.108s;

			&::after {
				top: 7.1em;
				left: 4.8em;
			}
		}

		&:nth-child(4) {
			animation-delay: -0.144s;

			&::after {
				top: 7.2em;
				left: 4em;
			}
		}

		&:nth-child(5) {
			animation-delay: -0.18s;

			&::after {
				top: 7.1em;
				left: 3.2em;
			}
		}

		&:nth-child(6) {
			animation-delay: -0.216s;

			&::after {
				top: 6.8em;
				left: 2.4em;
			}
		}

		&:nth-child(7) {
			animation-delay: -0.252s;

			&::after {
				top: 6.3em;
				left: 1.7em;
			}
		}

		&:nth-child(8) {
			animation-delay: -0.288s;

			&::after {
				top: 5.6em;
				left: 1.2em;
			}
		}
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}