@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";

.cart_product {
	position: relative;
	display: flex;
	align-items: center;

	@include redesign_scrollbar;


	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 2px;

		background-color: $text_color;
		opacity: 0.4;
	}

	@include bp($point_2, min) {
		padding: 1.2rem 2rem;
	}

	@include bp($point_3) {
		padding: 1.2rem;
	}

	@include bp($point_4 - 1) {
		flex-wrap: wrap;
		justify-content: center;
		gap: 2rem;
	}
}

.cart_product__col {
	&:not(:first-child) {

		@include bp($point_4, min) {
			display: flex;
			justify-content: center;
		}
	}

	@include bp($point_2, min) {
		&:nth-child(1) {
			width: calc(100% - 38rem);
		}

		&:nth-child(2) {
			width: 18rem;
		}

		&:nth-child(3) {
			width: 20rem;
		}

		&:nth-child(4) {
			width: 14rem;
		}
	}

	@include bp($point_3, $point_4) {
		&:nth-child(1) {
			width: calc(100% - 44rem);
		}

		&:nth-child(2) {
			width: 18rem;
		}

		&:nth-child(3) {
			width: 12rem;
		}

		&:nth-child(4) {
			width: 14rem;
		}
	}

	@include bp($point_4 - 1) {
		width: 100%;
		display: flex;
		justify-content: center;
		text-align: center;
	}
}

.cart__delete {
	width: 3rem;
	height: 3rem;

	font-size: 3rem;

	color: $text_color;

	transition: color 0.3s ease 0s;
	will-change: color;


	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}

.cart_product__descr {
	@include bp($point_2, min) {
		display: flex;
		align-items: center;
	}
}

.cart_product__image {
	display: block;
	flex: 0 0 12rem;
	border-radius: .4rem;
	overflow: hidden;

	transition: opacity 0.3s ease 0s;
	will-change: opacity;

	@media (any-hover: hover) {
		&:hover {
			opacity: 0.8;
		}
	}

	@include bp($point_3) {
		max-width: 12rem;

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}
	}

	@include bp($point_4 - 1) {
		margin-left: auto;
		margin-right: auto;
	}

	picture {
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 100%;

		img {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.cart_product__wrapper {
	@include bp($point_2, min) {
		padding: 2rem 1.6rem;
	}
}

.cart_product__name {
	display: block;
	font-family: $font_heading;

	font-size: 1.6rem;
	transition: color 0.3s ease 0s;
	will-change: color;

	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			color: $primary_color;
		}
	}
}

.cart_product__code {
	font-weight: 700;
	font-size: 1.4rem;

	strong {
		font-weight: 700;
		letter-spacing: .04em;
		background-color: $bg_color;
		padding: 0 .4rem;
		border-radius: .4rem;
	}

	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}
}

.cart_product__promo_title {
	font-size: 1.4rem;
	line-height: 1;
	color: $orange;
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: .4rem;
	}
}

.cart_product__promo_descr {
	font-size: 1.4rem;
	opacity: .9;
}