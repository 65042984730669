@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/buttonsMixins";

.ordering_section {
	padding: 3rem var(--gl-indent);
}

.ordering_section__wrap {
	padding: 2rem;

	background-color: $bg_dedicated_color;
	border-radius: .8rem;
	overflow: hidden;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}


.ordering_section__item {
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.ordering_section__bottom {
	display: flex;
	gap: 2rem;
	flex-direction: column;
	align-items: flex-end;
	padding-top: 2rem;
}

.ordering_section__amount {
	display: flex;
	align-items: center;

	padding: 1.8rem;
	background: $bg_color;
	border-radius: .8rem;

	@include bp($point_2, min) {
		min-width: 40rem;
	}

	@include bp($point_3) {
		min-width: 30rem;
	}
}

.ordering_section__label {
	flex: 1 1 auto;

	font-weight: 700;
	font-size: 2rem;

	margin-right: 1rem;
}

.ordering_section__value {}

.order_section__content {
	font-size: 1.8rem;
	text-align: center;

	*:not(:last-child) {
		margin-bottom: 2rem;
	}

	h4 {
		font-weight: 700;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	strong {
		display: block;
		color: $primary_color;
	}

	button {
		width: 100%;

		@include btn_size_base;
		@include btn_style_secondary;
	}
}

.ordering_section__row {
	display: flex;
	align-items: flex-end;
	width: 100%;
	gap: 2rem;

	@include bp($point_4, min) {
		justify-content: space-between;
	}

	@include bp($point_4 - 1) {
		flex-direction: column;
	}
}

.ordering_section__promo {
	font-size: 1.8rem;

	@include bp($point_4 - 1) {
		order: 3;
	}
}