@import "../../globalStyles/mixins";
@import "../../globalStyles/variables";

$button_size: 3.6rem;

.lang_button {
	position: relative;

	font-size: 1.4rem;
	text-transform: uppercase;
	text-align: center;

	width: $button_size;

	&::after {
		@include bp($point_6, min) {
			content: '';
			position: absolute;
			bottom: -1rem;
			left: 0;

			height: 1rem;
			width: 100%;

			opacity: 0;
		}
	}
}

.lang_button__current {

	border-radius: 50%;

	color: $text_color;
	text-transform: uppercase;
	border: .1rem solid $text_color;
	background: transparent;

	transition: background .3s ease, border-color .3s ease;

	.lang_button--open_state & {
		border-color: $primary_color;
		background: $primary_color;
	}

	@include bp($point_6, min) {
		width: $button_size;
		height: $button_size;
	}

	@include bp($point_6 - 1) {
		width: $button_size;
		height: $button_size;
	}
}

.lang_button__list {
	position: absolute;
	left: 0;
	z-index: 5;

	width: 100%;
	height: auto;

	overflow-x: hidden;
	overflow-y: auto;

	padding: 1.2rem .4rem;
	background-color: $bg_dedicated_color;
	border-radius: 3rem;
	box-shadow: rgba($black, .4) 0 .7rem 2.9rem 0;

	opacity: 0;
	transform: translateY(-3.6rem);
	pointer-events: none;

	transition: opacity .3s ease, transform .3s ease;
	will-change: transform;

	.lang_button--open_state & {
		opacity: 1;
		transform: translateY(0);
		pointer-events: auto;
	}

	@include redesign_scrollbar;

	@include bp($point_6, min) {
		top: calc($button_size + .4rem);
	}

	@include bp($point_6 - 1) {
		top: calc($button_size + .4rem);
	}
}

.lang_button__item {
	display: block;

	&:not(:last-child) {
		margin-bottom: .4rem;
	}
}

.lang_button__action {
	display: block;
	border-radius: 50%;

	width: calc($button_size - .8rem);
	height: calc($button_size - .8rem);

	font-size: inherit;
	text-transform: uppercase;
	color: $text_color;

	transition: background .3s ease;

	&.lang_button__action--current_state {
		background-color: $primary_color;
		pointer-events: none;
	}

	@media (any-hover: hover) {
		&:hover {
			background-color: $primary_color;
		}
	}
}