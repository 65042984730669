@import "../../../../globalStyles/variables";

.ordering_pickup {
	width: 100%;
}

.ordering_pickup__block {
	border-radius: .8rem;
	overflow: hidden;
	padding: 1rem;
	background-color: $bg_color;
	box-shadow: rgba($black, .05) 0 .6rem 2.4rem 0, rgba($black, .08) 0 0 0 .1rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.ordering_pickup__title {
	font-size: 1.6rem;
	font-weight: 700;

	.ordering_pickup__block--diclaimer_mod & {
		color: $primary_color;
	}

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.ordering_pickup__text {
	font-size: 1.4rem;

	strong {
		font-weight: 700;
		color: $primary_color;
	}

	a {
		transition: color 0.3s ease 0s, border-color 0.3s ease 0s;
		will-change: color, border-color;
		border-bottom: 1px solid $text_color;

		@media (any-hover: hover) {
			&:hover {
				text-decoration: none;
				border-color: $primary_color;
				color: $primary_color;
			}
		}
	}
}