@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/formMixins";
@import "../../globalStyles/buttonsMixins";

.reviews_form {
	padding-bottom: 4rem;
}

.reviews_form__wrap {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.reviews_form__row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -4rem -2rem 0;
}

.reviews_form__column {
	padding: 0 4rem 2rem 0;

	@include bp($point_2, min) {

		&:not(.reviews_form__column--full_mod) {
			width: 33.333%;
		}

		&.reviews_form__column--full_mod {
			width: 100%;
		}
	}

	@include bp($point_3) {
		width: 100%;
	}
}

.reviews_form__input {
	@include default_input;
}

.reviews_form__bottom {
	display: flex;
	justify-content: center;
}

.reviews_form__button {
	@include btn_size_lg;
	@include btn_style_primary;
}

.reviews_form__content {
	padding: 0 2rem;
	text-align: center;
}

.reviews_form__title {
	font-size: 2rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.reviews_form__text {
	font-size: 1.6rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.reviews_form__wrapper {
	display: flex;
	gap: 4rem;
}

.reviews_form__popup_button {
	&.reviews_form__popup_button--half_mod {
		width: calc(50% - 2rem);
	}

	&:not(.reviews_form__popup_button--half_mod) {
		width: 100%;
	}

	@include btn_size_base;

	&.reviews_form__popup_button--primary_mod {
		@include btn_style_primary;
	}

	&:not(.reviews_form__popup_button--primary_mod) {
		@include btn_style_secondary;
	}
}