@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.characteristics {
	display: block;
	width: 100%;
}

.characteristics__body {
	display: block;
	width: 100%;
}

.characteristics__item {
	position: relative;

	display: flex;
	align-items: center;
	margin-right: -2rem;

	text-align: left;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: calc(var(--gl-indent) * -1);

		width: 100vw;

		transition: opacity .3s ease;
	}

	&::after {
		z-index: -1;

		height: 100%;

		background-color: $bg_dedicated_color;
		opacity: .2;
	}

	&::before {
		height: 2px;

		background-color: $text_color;
		opacity: .4;
	}

	@media (any-hover: hover) {
		&:hover {
			&::after {
				opacity: .8;
			}

			&::before {
				opacity: .6;
			}
		}
	}

	@include bp($point_4, min) {
		padding: 1.8rem .6rem;
		font-size: 1.6rem;
	}

	@include bp($point_4 - 1) {
		padding: 1.8rem 0;
		font-size: 1.4rem;
	}

	td {
		display: block;
		padding-right: 2rem;

		&:nth-child(2n+1) {
			width: 36%;

			font-weight: 700;
		}

		&:nth-child(2n+2) {
			width: 64%;
		}
	}

	.price__val--gray_mod{
		color: $text_color;
	}
}