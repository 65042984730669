@import "../../globalStyles/variables";

.rating_field {
	display: block;
	width: 100%;
}

.rating_field__body {
	display: flex;
	height: $input_height;
	align-items: center;
}