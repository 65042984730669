@import "../../globalStyles/variables";

.password_input {
	position: relative;
	width: 100%;
}

.password_input__button {
	position: absolute;
	z-index: 2;
	top: 50%;
	transform: translateY(-50%);
	right: .6rem;

	width: 2rem;
	height: 2rem;

	font-size: 2rem;
	color: $text_color;

	span {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
	}
}

.password_input__icon {
	position: absolute;
	top: 0;
	left: 0;
	transition: transform 0.3s ease-in-out 0s;
	will-change: transform;

	transform: scale(0);

	&.password_input__icon--active_state {
		transform: scale(1);
	}
}