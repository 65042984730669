@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.logo {
	display: block;

	color: $invert_color;
	transition: color 0.3s ease;

	width: 18rem;
	height: 3rem;
}

.logo__in {
	display: block;
	width: 100%;
	height: 100%;
}