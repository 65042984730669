@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/buttonsMixins";

.info_email {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	padding: 4rem var(--gl-indent);
	min-height: calc(100vh - var(--height-header));

	h1 {
		font-size: 2.2rem;

		max-width: 46rem;
		margin-left: auto;
		margin-right: auto;

		&:not(:last-child) {
			margin-bottom: 4rem;
		}
	}

	a {
		@include btn_style_primary;
		@include btn_size_base;
	}
}