@import "../../globalStyles/variables";

.mark_rating {
	display: flex;
	align-items: center;
}


.mark_rating__star {
	display: block;
	position: relative;
	background-color: $bg_color;

	font-size: 2.4rem;
	color: $primary_color;
	cursor: pointer;

	&:not(:last-child) {
		margin-right: .6rem;
	}
}

.mark_rating__wrap {
	position: relative;
	top: 2.2rem;
	left: .7rem;
	overflow: hidden;

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 1px;
		z-index: -1;
	}
}

.mark_rating__fill {
	position: absolute;
	top: 0;
	left: 0;

	opacity: 0;
	transition: opacity 0.3s ease 0s, transform 0.3s ease-in-out 0s;
	will-change: transform;

	&.mark_rating__fill--visible_mod {
		opacity: 1;
	}

	@media (any-hover: hover) {
		&:hover {
			transform: scale(1.2);
		}
	}
}