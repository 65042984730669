@import "../../../globalStyles/variables";

.notification_item {}

.notification_item__button {
	display: flex;
	width: 100%;
	align-items: center;

	gap: 2rem;
	padding: 1.6rem;

	font-size: 1.4rem;
	color: $text_color;

	cursor: pointer;
}


.notification_item__image {
	display: block;

	width: 6rem;
	height: 6rem;

	flex-shrink: 0;
	border-radius: 50%;
	overflow: hidden;
}

.notification_item__main {
	flex: 1 1 auto;

	display: flex;
	gap: 1rem;
	align-items: center;
}

.notification_item__title {
	font-size: 1.6rem;
	font-weight: 700;
	text-align: left;

	transition: color .3s ease;

	.notification_item--active_state & {
		color: $primary_color;
	}
}

.notification_item__arrow {
	flex-shrink: 0;
	font-size: 1.8rem;

	transition: transform 0.3s ease 0s, color .3s ease;
	will-change: transform;

	.notification_item--active_state & {
		transform: rotateX(-180deg);
		color: $primary_color;
	}
}

.notification_item__wrap {}

.notification_item__status {
	width: 1.6rem;
	height: 1.6rem;
	margin-left: auto;
	flex-shrink: 0;
	border-radius: 50%;

	transition: background .3s ease;

	&:not(.notification_item--read_state &) {
		background: $primary_color;
	}

	.notification_item--read_state & {
		background: grey;
	}

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.notification_item__date {
	flex-shrink: 0;
	opacity: .8;
}

.notification_item__content {
	overflow: hidden;
	height: 0;
}

.notification_item__footer {
	padding: 1.6rem;
	font-size: 1.4rem;
	line-height: 1.5;
}