@import "../../globalStyles/variables";

.label {
	display: block;

	color: $text_color;
	font-weight: 700;
	font-size: 1.4rem;

	&:not(:last-child) {
		margin-bottom: .6rem;
	}

	&[for] {
		cursor: pointer;
	}
}

.label__sign {
	display: inline-block;
	margin-right: .4rem;

	color: $primary_color;
}