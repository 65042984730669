@import "../../globalStyles/variables";

.payments {}

.payments__list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	margin: 0 -2rem -2rem 0;
}

.payments__item {
	padding: 0 2rem 2rem 0;
}

.payments__logo {
	color: $text_color;

	display: flex;
	align-items: center;

	&.payments__logo--mod_1 {
		width: 5rem;
		height: 4rem;
	}

	&.payments__logo--mod_2 {
		width: 7rem;
		height: 2.6rem;
	}

	&.payments__logo--mod_3 {
		width: 5rem;
		height: 5rem;
	}

	svg {
		width: 100%;
		height: 100%;
		color: $text_color;
	}
}