@import "../../globalStyles/variables";

.theme_button {
	width: 2.6rem;
	height: 2.6rem;
	border-radius: 50%;
	overflow: hidden;
}

.theme_button__icon {
	transition: color 0.3s ease 0s, transform 0.3s ease 0s;
	will-change: transform, color;

	color: $text_color;
	font-size: 2.6rem;

	@media (any-hover: hover) {
		.theme_button:hover & {
			transform: rotate(-180deg);
			color: $primary_color;
		}
	}
}