@import "../../globalStyles/variables";
@import "../../globalStyles/buttonsMixins";

.user_button {
	display: block;
	width: 2rem;
	height: 2.4rem;

	color: $text_color;
	transition: color .3s ease;

	&.user_button--current_state {
		color: $primary_color;
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}

.user_button__icon {
	color: inherit;

	&.user_button__icon--big_mod {
		font-size: 2.4rem;
	}

	&:not(.user_button__icon--big_mod) {
		font-size: 2rem;
	}
}

.user_button__content {
	text-align: center;

	padding: 0 2rem;
}

.user_button__title {
	font-size: 2rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.user_button__text {
	font-size: 1.6rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.user_button__popup_button {
	@include btn_size_base;
	@include btn_style_secondary;
	width: 100%;
}