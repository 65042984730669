@import "../../globalStyles/formMixins";
@import "../../globalStyles/mixins";

.price_slider {}

.price_slider__wrap {
	display: flex;
	align-items: center;
	max-width: 100%;

	margin-right: -.6rem;

	&:not(:last-child) {
		margin-bottom: 1.6rem;
	}
}

.price_slider__item {
	padding-right: .6rem;
	font-size: 1.6rem;

	&.price_slider__item--input_mod {
		flex: 1 1 auto;
	}
}

.price_slider__input {
	@include range_input;
}

.price_slider__range {
	padding: 1.2rem;
}