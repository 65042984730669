@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.dropdown {
	@include bp($point_2, min) {
		position: absolute;
		top: 0;
		z-index: 5;

		width: 100%;
		height: auto;
		padding-top: 4rem;

		max-height: calc(100vh - 3rem);
		overflow-x: hidden;
		overflow-y: auto;

		background-color: $bg_dedicated_color;
		transition: opacity .3s ease-out;
		opacity: 0;
		pointer-events: none;
		visibility: hidden;

		&.dropdown--open_state {
			visibility: visible;
			opacity: 1;
			pointer-events: auto;
		}

		@include redesign_scrollbar;
	}

	@include bp($point_3) {
		transition: all .3s ease-in-out 0s;

		opacity: 0;
		pointer-events: none;
		overflow: hidden;
		max-height: 0px;

		&.dropdown--open_state {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.dropdown__list {
	width: 100%;
}