@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

$burger_height: 2rem;

.burger_button {
	position: relative;
	z-index: 6;

	width: 3.4rem;
	height: $burger_height;

	&::before,
	&::after,
	span {
		position: absolute;
		left: 0;
		top: 50%;

		width: 100%;
		height: .2rem;

		transition: transform .3s ease;
		will-change: transform;

		background-color: $text_color;
	}

	&::before {
		content: "";
		transform: translateY(calc($burger_height / 2 * -1));
	}

	span {
		transform: translateY(-50%);
	}

	&::after {
		content: "";
		transform: translateY(calc($burger_height / 2 - 100%));
	}

	.body--open_menu_state & {
		span {
			transform: scale(0);
		}

		&::before {
			transform: rotate(-45deg);
		}

		&::after {
			transform: rotate(45deg);
		}
	}
}