@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";
@import "../../globalStyles/buttonsMixins";

.product_description {
	height: 100%;
	width: 100%;

	overflow: hidden;
}

.product_description__title {
	@include h3;

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}
}

.product_description__wrap {
	&.product_description__wrap--labels_mod {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
	}

	&.product_description__wrap--prcies_mod {
		border-radius: .4rem;
		overflow: hidden;
	}

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.product_description__row {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-right: -2rem;
}

.product_description__column {
	padding-right: 2rem;
}

.product_description__label {
	position: relative;
	z-index: 2;

	display: inline-block;
	padding: 0.4rem 0.8rem;
	border-radius: 0.4rem;
	overflow: hidden;

	color: $text_color;
	text-align: center;
	font-weight: 700;
	font-size: 1.4rem;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100%;

		content: "";
		opacity: 0.3;
	}

	&.product_description__label--available_mod::before {
		background-color: $green;
	}

	&.product_description__label--not_available_mod::before {
		background-color: $primary_color;
	}

	&.product_description__label--sale_mod::before {
		background-color: $blue;
	}

	&.product_description__label--promo_mod::before {
		background-color: $orange;
	}

	&.product_description__label--pair_mod::before {
		background-color: $purple;
	}
}

.product_description__code {
	font-size: 1.6rem;

	display: flex;
	align-items: center;

	span {
		display: block;
		padding: 0.2rem 0.6rem;
		border-radius: 0.2rem;
		background-color: rgba($black, 0.2);

		font-size: 1.4rem;
		color: $text_color;
	}
}

.product_description__list {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.product_description__item {
	position: relative;

	padding-left: 1.2rem;

	font-size: 1.4rem;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&::before {
		position: absolute;
		top: 0.7rem;
		left: 0;

		width: 0.4rem;
		height: 0.4rem;
		border-radius: 50%;

		background-color: $primary_color;
		content: "";
	}
}

.product_description__editor {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}

	ul,
	ol,
	p {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	p {
		font-size: 1.6rem;
	}

	ul>li {
		position: relative;

		padding-left: 1.2rem;

		font-size: 1.4rem;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		&::before {
			position: absolute;
			top: 0.7rem;
			left: 0;

			width: 0.4rem;
			height: 0.4rem;
			border-radius: 50%;

			background-color: $primary_color;
			content: "";
		}
	}
}

.product_description__instruction {
	position: relative;

	display: inline-flex;
	align-items: center;

	font-size: 1.4rem;
	transition: color 0.3s ease 0s;

	span {
		display: block;
		font-size: 1.6rem;
		margin-left: .6rem;
		margin-top: .4rem;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: .2rem;
		left: 0;

		width: 100%;
		height: .1rem;

		background-color: $primary_color;
	}

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			color: $primary_color;
		}
	}
}

.product_description__alert {
	display: inline-block;
	background: rgba($yellow, 0.3);
	padding: 0.4rem 0.8rem;
	border-radius: 0.4rem;

	font-size: 1.4rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.product_description__block {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}

	@include bp($point_6, min) {
		margin-right: -4rem;
	}

	@include bp($point_6 - 1) {
		margin-right: -2rem;
	}
}

.product_description__action {
	color: $text_color;

	@include bp($point_6, min) {
		padding-right: 4rem;
	}

	@include bp($point_6 - 1) {
		padding-right: 2rem;
	}
}

.product_description__button {
	min-width: 17rem;

	@include btn_size_lg;

	&.product_description__button--not_available_state {
		font-size: 3rem;
	}

	&:not(.product_description__button--active_state, .product_description__button--not_available_state) {
		@include btn_style_secondary;
	}

	span.font_icon {
		&:not(:last-child) {
			margin-right: 0.8rem;
		}

		&:not(:first-child) {
			margin-left: 0.8rem;
		}
	}
}

.product_description__options {
	display: flex;
	flex-wrap: wrap;
	gap: 1.6rem;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.product_description__option {
	display: block;
	padding: .6rem 1rem;

	border: .1rem solid $text_color;
	border-radius: .4rem;

	font-size: 1.4rem;
	color: $text_color;

	transition: color 0.3s ease, background 0.3s ease, border-color .3s ease, opacity .3s ease;
	will-change: background, border-color, opacity;

	&.product_description__option--active_state {
		background: rgba($primary_color, .1);
		border-color: $primary_color;
		pointer-events: none;
	}

	&.product_description__option--disabled_state {
		position: relative;
		overflow: hidden;

		opacity: 0.6;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			width: 100%;
			height: 1px;

			background-color: currentColor;
		}

		&::before {
			transform: rotate(25deg);
		}

		&::after {
			transform: rotate(-25deg);
		}

		@media (any-hover: hover) {
			&:hover {
				text-decoration: none;
				border-color: $grey;
				color: $grey;

				opacity: 1;
			}
		}
	}

	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
			border-color: $primary_color;
			color: $primary_color;
		}
	}
}

.product_promotion__link {
	text-decoration: none;
	color: inherit;
	display: block;
	border-radius: 8px;
	overflow: hidden;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	margin-bottom: 10px;

	&:hover {
		text-decoration: none;
	}

	&:active {
		transform: scale(0.98);
		text-decoration: none;
		border-radius: 8px;
	}
}

.product_promotion__row {
	display: flex;
	align-items: center;
	height: 90px;
	padding: 12px;
	background-color: $bg_dedicated_color;
	border-radius: 8px;
	margin: 0 0 10px;

	// &:hover {
	// 	background-color: $bg_menu_color;
	// }
}

.product_promotion__column1 {
	flex: 0 0 87px;
}

.product_promotion__cover {
	width: 87px;
	height: 66px;
	border-radius: 8px;
	overflow: hidden;
	object-fit: cover;
}

.product_promotion__column2 {
	position: relative;
	height: 100%;
	flex: 1;
	padding-left: 10px;

	.promotion_description__title {
		font-size: 16px;
		font-weight: 600;
		font-family: $font_text;
		margin: 0;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 19.5px;

		@include bp($point_4) {
			font-size: 14px;
		}

		@include bp($point_6) {
			font-size: 12px;
		}
	}

	.promotion_description__description {
		position: absolute;
		bottom: -10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		font-size: 12px;
		color: gray;
		font-family: $font_text;
		text-wrap: nowrap;

		@include bp($point_4) {
			font-size: 10px;
		}

		@include bp($point_4) {
			font-size: 8px;
		}
	}
}

.product_promotion__column3 {
	margin-left: 35px;
}

.product_quantity {
	display: flex;
	width: 170px;
	height: 50px;
	align-items: center;
	justify-content: space-between;
	background-color: #666666;
	padding: 5px 15px;
	border-radius: 25px;

	@include bp($point_6) {
		width: 100px;
		height: 32px;
	}
}

.product_quantity__num {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: $white;
	width: 50px;
}

.product_quantity__btn {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 2.6rem;

	font-size: 2.1rem;
	color: $white;

	transition: color 0.3s ease, opacity 0.3s ease;
	will-change: color;

	&.quantity__btn--disabled_mod {
		opacity: 0.6;
		pointer-events: none;
	}

	@media (any-hover: hover) {
		&:hover {
			color: $primary_color;
		}
	}
}