@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";


.products {
	padding: 3rem var(--gl-indent);
}

.products__body {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -2rem -2rem 0;

	@include bp($point_3) {
		align-items: center;
	}
}

.products__column {
	padding: 0 2rem 2rem 0;

	@include bp($point_2, min) {
		&:nth-child(2n+1) {
			width: 36rem;
		}

		&:nth-child(2n+2) {
			width: calc(100% - 36rem);
		}

		&:last-child:nth-child(odd) {
			width: 100%;
		}
	}

	@include bp($point_3) {

		&:nth-child(2) {
			width: calc(100% - 8rem);
		}

		&:nth-child(3) {
			width: 8rem;
		}


		&:not(:nth-child(2),
			:nth-child(3)) {
			width: 100%;
		}

	}
}

.products__title {
	@include h4;
}

.products__filter_trigger {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 5rem;
	height: 5rem;
	border-radius: 50%;

	font-size: 2.4rem;
	color: $text_color;
	cursor: pointer;
	border: .1rem solid $text_color;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100%;
		border-radius: inherit;
		pointer-events: none;
		background: rgba($black, .1);
		box-shadow: rgba($black, .2) .3rem .3rem .6rem 0 inset, rgba($black, .2) -.3rem -.3rem .6rem 0 inset;
	}

	&.products__filter_trigger--cross_mod {
		position: fixed;
		top: 2rem;
		right: 1.2rem;
		z-index: 6;
	}

	&:not(.products__filter_trigger--cross_mod) {
		position: relative;
	}
}

.products__aside {
	width: 100%;

	@include bp($point_3) {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;

		height: 100%;
		max-height: 100vh;

		padding-top: 8rem;
		padding-bottom: 4rem;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: $bg_menu_color;

		transition: transform .3s ease;
		will-change: transform;

		&:not(.products__aside--open_state) {
			pointer-events: none;
			transform: translateX(100%);
		}

		&.products__aside--open_state {
			pointer-events: initial;
			transform: translateX(0);
		}

		@include redesign_scrollbar;
	}
}

.products__main {
	width: 100%;
}