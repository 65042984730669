@import "../../globalStyles/variables";

.like_button {
	position: relative;

	display: block;
	width: 3rem;
	height: 3rem;

	color: inherit;
}

.like_button__item {
	position: absolute;
	top: 0;
	left: 0;

	font-size: 3rem;

	transition: transform .3s ease, color .3s ease;
	will-change: transform;

	&:not(.like_button__item--filled_mod) {
		color: inherit;

		@media (any-hover: hover) {
			&:hover {
				color: $primary_color;
			}
		}

		.like_button--active_state & {
			transform: scale(0);
		}
	}

	&.like_button__item--filled_mod {
		color: $primary_color;

		transform: scale(0);

		.like_button--active_state & {
			transform: scale(1);
		}
	}
}