@import "../../globalStyles/variables";
@import "../../globalStyles/typographyMixins";

.categories_list {
	padding: 3rem var(--gl-indent);
	min-height: 60vh;
}

.categories_list__title {
	@include h4;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.categories_list__wrap {
	&:not(:last-child) {
		margin-bottom: 4rem;
	}
}

.categories_list__body {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	margin: 0 -4rem -4rem 0;
}

.categories_list__item {
	padding: 0 4rem 4rem 0;

	@include bp($point_9, min) {
		width: 25%;
	}

	@include bp($point_9 - 1, $point_4) {
		width: 33.333%;
	}

	@include bp($point_4 - 1, $point_6) {
		width: 50%;
	}

	@include bp($point_6 - 1) {
		width: 100%;
	}
}

.categories_list__descr {
	font-size: 1.6rem;
	line-height: 1.5;
}