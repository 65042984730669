@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.dropdown_item {
	min-width: 14rem;
	width: 100%;
}

.dropdown_item__wrap {
	position: relative;

	@include bp($point_2, min) {
		display: flex;
	}
}

.dropdown_item__link {
	position: relative;
	z-index: 6;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	min-height: 3.5rem;

	padding: .8rem;

	color: $text_color;
	transition: background .3s ease, color .3s ease;

	&.dropdown_item__link--current_state {
		color: $primary_color;
	}

	// select by tag
	@at-root {
		a#{&} {
			@media (any-hover: hover) {
				&:hover {
					text-decoration: none;

					// hover for default link
					&:not(.dropdown_item__wrap &) {
						color: $primary_color;
					}

					.dropdown & {
						background-color: rgba(244, 64, 54, .2);
					}
				}
			}
		}

		button#{&} {
			@include bp($point_2, min) {

				transition: background .3s ease;
				background-color: transparent;

				&:not(.dropdown &) {
					&::before {
						position: absolute;
						top: 0;
						left: 0;
						z-index: 6;

						width: 100%;
						height: .2rem;

						background-color: $primary_color;
						pointer-events: none;
						opacity: 0;
						transition: opacity .3s ease;
						content: '';
					}

					.dropdown_item--open_state & {
						background-color: $bg_dedicated_color;

						&::before {
							opacity: 1;
						}
					}
				}
			}

			@include bp($point_3) {
				transition: color .3s ease;

				&[aria-expanded="true"] {
					color: $primary_color;
				}
			}
		}
	}

	@include bp($point_3) {
		justify-content: center;
	}
}

.dropdown_item__icon {
	display: block;
	font-size: 2rem;

	color: inherit;

	&:not(:last-child) {
		margin-right: .4rem;
	}
}

.dropdown_item__arrow {
	display: block;
	font-size: 1.8rem;

	color: inherit;

	transition: transform .3s ease;
	will-change: transform;

	&:not(.dropdown &) {
		.dropdown_item--open_state & {
			transform: rotateX(-180deg);
		}
	}
}

.dropdown_item__text {
	display: block;

	text-align: left;
	color: inherit;

	&:not(:last-child) {
		margin-right: .4rem;
	}

	.dropdown & {
		font-size: 1.4rem;
		letter-spacing: -.02em;

		width: 100%;
	}

	&:not(.dropdown &) {
		font-size: 1.6rem;
		text-transform: uppercase;

		@include bp($point_3) {
			font-weight: 700;
		}
	}

	@include bp($point_3) {
		text-align: center;
	}
}