@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

$border_radius: .2rem;
$h_padding: 1rem;

.custom_select {
	height: $input_height;
	min-width: 13rem;

	font-size: $input_font_size;
	text-align: left;
	color: $text_color;

	&.custom_select--is-disabled {
		opacity: 0.4;
	}

	.custom_select__option {
		cursor: pointer;
	}

	.custom_select__control {
		min-height: $input_height;
		cursor: pointer;
		transition: box-shadow .3s ease;
	}
}

.custom_select__placeholder {
	color: $input_placeholder_color;
}

.custom_select__input-container {
	cursor: pointer;
}

.custom_select__value-container {
	&:not(:last-child) {
		margin-right: .6rem;
	}
}

.custom_select__control {
	padding: 0 $h_padding;
	width: 100%;
	height: 100%;

	border-radius: $border_radius;

	box-shadow: inset 0 0 0 .1rem $input_border_color;
	background-clip: padding-box;
	cursor: pointer;
	will-change: box-shadow;

	.custom_select--primary_mod & {
		background-color: $bg_color;
	}

	.custom_select--secondary_mod & {
		background-color: $bg_dedicated_color;
	}

	&.custom_select__control--is-focused {
		box-shadow: inset 0 0 0 .2rem $input_border_color;
	}

	.custom_select--error_mod & {
		box-shadow: inset 0 0 0 .1rem $primary_color;
	}

	&.custom_select__control--menu-is-open+.custom_select__menu {
		animation: animationAppear 0.3s ease-in-out;
	}
}

.custom_select__indicator {
	cursor: pointer;

	&.custom_select__dropdown-indicator {
		transition: transform 0.3s ease 0s;

		will-change: transform;

		.custom_select__control--menu-is-open & {
			transform: rotateЧ(-180deg);
		}
	}

	svg {
		@include bp($point_5, min) {
			width: 2rem;
			height: 2rem;
		}

		@include bp($point_5 - 1) {
			width: 1.6rem;
			height: 1.6rem;
		}
	}
}

.custom_select__menu {
	width: 100%;
	padding: .4rem 0;

	overflow-x: hidden;
	transform-origin: top;


	box-shadow: rgba(14, 30, 37, .12) 0 .2rem .4rem 0, rgba(14, 30, 37, .32) 0 .2rem 1.6rem 0;
	border-radius: 0 0 $border_radius $border_radius;

	.custom_select--primary_mod & {
		background-color: $bg_dedicated_color;
	}

	.custom_select--secondary_mod & {
		background-color: $bg_color;
	}
}

.custom_select__menu-list {
	@include redesign_scrollbar;
}

.custom_select__options {
	@include redesign_scrollbar;
}

.custom_select__option {
	position: relative;

	padding: 1.2rem $h_padding;

	font-size: 1.4rem;
	font-weight: 700;
	color: $text_color;

	transition: color .3s ease;
	will-change: color;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100%;

		background: $primary_color;
		content: '';
		pointer-events: none;
		opacity: 0;
		transition: opacity .3s ease;
	}

	&:not(:last-child) {
		border-bottom: .1rem solid $text_color;
	}

	@media (any-hover: hover) {
		&:hover {
			&::before {
				opacity: .6;
			}
		}
	}
}

@keyframes animationAppear {
	0% {
		opacity: 0;
		transform: scaleY(0);
	}

	100% {
		opacity: 1;
		transform: scaleY(1);
	}
}