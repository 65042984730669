@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";
@import "../../globalStyles/typographyMixins";

.products_tabs {
	position: relative;
	padding: 3rem var(--gl-indent);
}

.products_tabs__head {
	display: flex;
	align-items: flex-end;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.products_tabs__title {
	@include h3;

	&:not(:last-child) {
		margin-right: 2rem;
	}
}

.products_tabs__wrap {
	@include showHorizontalScroll(6rem);
}

.products_tabs__nav {
	padding: .6rem 0 1rem;
	margin-left: -0.6rem;

	overflow-x: auto;
	overflow-y: hidden;
	@include hide_scrollbar;
}

.products_tabs__list {
	display: flex;
	flex-wrap: nowrap;

	padding-left: .6rem;
}

.products_tabs__item {
	flex-shrink: 0;

	&:not(:last-child) {
		padding-right: .8rem;
	}

	&:last-child {
		padding-right: 4rem;
	}
}

.products_tabs__button {
	position: relative;

	padding: .5rem 1rem;

	font-size: 1.4rem;
	color: $text_color;
	background-color: $bg_dedicated_color;
	border-radius: .4rem;

	cursor: pointer;

	&::before {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
		border-radius: inherit;
		border: .2rem solid $primary_color;
		box-shadow: $black 0 0 .2rem, $black 0 0 .2rem;

		content: '';
		opacity: 0;
		transition: opacity 0.3s ease 0s;
	}

	.react-tabs__tab--selected & {
		&::before {
			opacity: .8;
		}
	}

	@media (any-hover: hover) {
		&:hover {
			&::before {
				opacity: .8;
			}
		}
	}
}