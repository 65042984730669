@import "../../globalStyles/variables";
@import "../../globalStyles/mixins";

.available_magnitols_table {
	display: block;
	overflow-x: auto;
	overflow-y: hidden;

	border-radius: .2rem;
	background-color: $bg_dedicated_color;
	border-left: 1px solid $grey;
	border-top: 1px solid $grey;
}

.available_magnitols_table__in {
	display: table;
	width: 100%;

	td {
		padding: .6rem 1rem;

		font-size: max(1.2rem, 10px);

		border-right: 1px solid $grey;
		border-bottom: 1px solid $grey;
	}

	thead {
		position: relative;

		font-weight: 700;
		background-color: rgba($black, .2);

		tr:nth-child(1) td {
			text-align: center;
		}

		tr:nth-child(2) td:nth-child(2) {
			text-align: center;
		}
	}

	thead tr,
	tbody tr {
		td:first-child {
			flex: 0 0 18rem;
		}

		td {
			flex: 1;
			min-width: 12rem;
		}
	}
}